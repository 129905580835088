import { useState, useRef, useEffect } from 'react';

function SearchInput(props) {
	const [value, setValue] = useState('');
	const queryTimeout = useRef(null);

	const onChange = newValue => {
		setValue(newValue);
		if (queryTimeout && queryTimeout.current) clearTimeout(queryTimeout.current);
		queryTimeout.current = setTimeout(() => {
			props.onChange(newValue);
		}, 500);
	};

	return (
		<div className="searchinput">
			<input type="text" placeholder="Keresés..." value={value} onChange={e => onChange(e.target.value)} />
		</div>
	);
}

export default SearchInput;
