import React, { Component } from 'react';
import Slides from '../components/slides/Slides';
import { withRouter } from 'react-router-dom';
import DataService from '../service/DataService';
import TagButton from '../items/TagButton';
import DetailsTimelineBar from '../components/details/DetailsTimelineBar';
import DetailsMobileInfo from '../items/DetailsMobileInfo';

class detailsPage extends Component {
	constructor(props) {
		super(props);
		this.loadCount = 10;
		this.callCount = 0;
		this.state = {
			query: null,
			person: null,
			contentTagList: null,
			filterList: [],
			contentList: null,
			contentKey: null,
			isLoading: false,
			lead: null,
			leadText: null,
			leadOpenable: false,
			tagsOpenable: false,
			leadOpened: false,
			tagsOpened: false,
			typeCountMap: null,
			slideBottomHeight: 0,
			mobileInfoMinHeight: 0,
			queryKey: null
		};
		this.container = null;
		this.detailsLeadContainer = null;
		this.detailsLeadContent = null;
		this.scrollableTagContainer = null;
	}

	checkParamsValid() {
		if (this.props.mainData != null && this.state.filterList.length > 0 && this.props.match.params.contentKey != null && this.props.match.params.personKey != null) return true;
		return false;
	}

	paramsChanged() {
		if (this.state.query == null || this.state.query.contentKey != this.props.match.params.contentKey || this.state.query.personKey != this.props.match.params.personKey) {
			return true;
		}
		return false;
	}

	stateFilterChanged(prevState) {
		if (prevState.filterList.length == 0 || prevState.filterList.length != this.state.filterList.length) return true;
		if (prevState.filterList.length == this.state.filterList.length) {
			for (var i = 0; i < this.state.filterList.length; i++) {
				if (prevState.filterList.indexOf(this.state.filterList[i]) == -1) return true;
			}
		}
		return false;
	}

	propsTagChanged(prevProps) {
		if ((prevProps.tag == null && this.props.tag != null) || (prevProps.tag != null && this.props.tag == null) || (prevProps.tag != null && this.props.tag != null && prevProps.tag.id != this.props.tag.id)) {
			return true;
		}
		return false;
	}

	propsSearchQueryChanged(prevProps) {
		if (prevProps.searchQuery !== this.props.searchQuery) {
			return true;
		}
		return false;
	}

	componentDidMount() {
		if (this.checkParamsValid() && this.paramsChanged()) {
			this.setState(
				{
					query: {
						contentKey: this.props.match.params.contentKey,
						personKey: this.props.match.params.personKey
					}
				},
				() => this.updateByQuery()
			);
		}
		window.addEventListener(
			'resize',
			function () {
				this.updateSizes();
			}.bind(this)
		);
		this.updateSizes();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.checkParamsValid() && (this.paramsChanged() || this.stateFilterChanged(prevState) || this.propsTagChanged(prevProps) || this.propsSearchQueryChanged(prevProps))) {
			if (this.stateFilterChanged(prevState) || this.propsTagChanged(prevProps) || this.propsSearchQueryChanged(prevProps)) {
				this.setState(
					{
						queryKey: this.propsTagChanged(prevProps) ? null : this.state.queryKey,
						query: {
							contentKey: this.props.match.params.contentKey,
							personKey: this.props.match.params.personKey
						},
						contentList: null
					},
					() => this.updateByQuery()
				);
			} else {
				this.setState(
					{
						query: {
							contentKey: this.props.match.params.contentKey,
							personKey: this.props.match.params.personKey
						}
					},
					() => this.updateByQuery()
				);
			}
		}
	}

	updateSizes() {
		if (this.container != null && this.detailsLeadContainer != null && this.scrollableTagContainer != null && this.detailsContentContainer != null) {
			const desktopMatch = window.matchMedia('(min-width: 992px)');
			if (desktopMatch.matches) {
				this.container.style.height = window.innerHeight - 65 + 'px';
				this.container.style.minHeight = '0px';
				this.detailsContentContainer.style.flex = '1';
				this.detailsContentContainer.style.height = 'auto';
				this.detailsContentContainer.style.minHeight = '0';
				this.detailsLeadContainer.style.height = 'auto';
				this.scrollableTagContainer.style.height = 'auto';
			} else {
				this.container.style.height = 'auto';
				this.container.style.minHeight = window.innerHeight - 80 + 'px';
				this.detailsContentContainer.style.flex = '0 1 auto';
				this.detailsContentContainer.style.height = (window.innerHeight - 80) * 0.75 + 'px';
				this.detailsContentContainer.style.minHeight = window.innerWidth * 0.85 + 'px';
				var textBlockHeight = this.calcMobileTextBlockHeight();
				this.detailsLeadContainer.style.height = textBlockHeight + 'px';
				this.scrollableTagContainer.style.height = textBlockHeight + 'px';
				if (this.detailsLeadContent.offsetHeight > textBlockHeight) {
					this.setState({ leadOpenable: true });
				} else {
					this.setState({ leadOpenable: false });
				}
				if (this.tagContent.offsetHeight > textBlockHeight) {
					this.setState({ tagsOpenable: true });
				} else {
					this.setState({ tagsOpenable: false });
				}
				this.setState({
					mobileInfoMinHeight: (window.innerHeight - 65 - 76) * 0.25
				});
			}
		}
	}

	calcMobileTextBlockHeight() {
		return ((window.innerHeight - 80) * 0.4 - 15) / 2;
	}

	updateByQuery() {
		if (this.state.contentList != null) {
			var item = this.state.contentList.find(item => item.key == this.state.query.contentKey);
			if (typeof item !== 'undefined') {
				this.updateLeftBar();
			} else {
				this.updateContent();
			}
		} else {
			this.updateContent();
		}
	}

	updateLeftBar() {
		var item = this.state.contentList.find(item => item.key == this.state.query.contentKey);
		this.setState(
			{
				contentKey: this.state.query.contentKey,
				lead: item.loaded ? item.data.description : null,
				leadText: item.loaded ? item.data.descriptionText : null,
				contentTagList: item.loaded ? item.data.tagList : null
			},
			() => this.updateSizes()
		);
		this.props.onServiceTagListChange(item.loaded ? item.data.tagList : null);
	}

	updateContent() {
		var person = this.state.query.personKey !== 'mindenki' ? this.props.mainData.personList.find(p => p.key == this.state.query.personKey) : null;
		this.setState(
			{
				person: person,
				isLoading: true
			},
			() => {
				this.updateSizes();
				this.callCount++;
				let _callCount = this.callCount;
				DataService.getListByContent(
					this.state.query.contentKey,
					this.loadCount,
					this.loadCount,
					this.state.person ? this.state.person.id : null,
					this.state.filterList,
					this.props.tag != null ? this.props.tag.name : null,
					this.state.queryKey,
					this.props.searchQuery
				).then(result => {
					if (this.callCount != _callCount) return;
					var contentList = new Array(result.listLength);
					contentList = contentList.fill(null);
					var startPos = result.listIndex - result.contentIndex;
					var arrayIndex = 0;
					contentList = contentList.map((item, i) => {
						if (i >= startPos && i < startPos + result.contentList.length) {
							var newItem = result.contentList[arrayIndex];
							arrayIndex++;
							return {
								key: result.listKeySet[i],
								loaded: true,
								data: newItem
							};
						}
						return {
							key: result.listKeySet[i],
							loaded: false,
							data: null
						};
					});
					var item = contentList.find(item => item.key == this.state.query.contentKey);
					if (item != null) {
						this.setState(
							{
								contentList: contentList,
								contentKey: this.state.query.contentKey,
								isLoading: false,
								lead: item.loaded ? item.data.description : null,
								leadText: item.loaded ? item.data.descriptionText : null,
								contentTagList: item.loaded ? item.data.tagList : null,
								typeCountMap: result.typeCountMap,
								queryKey: result.key
							},
							() => this.updateSizes()
						);
						this.props.onServiceTagListChange(item.loaded ? item.data.tagList : null);
					} else {
						var newState = '/reszletek/' + this.props.match.params.personKey + '/' + result.contentList[result.contentIndex].key;
						this.props.history.push(newState);
					}
				});
			}
		);
	}

	handleTagChange(tag) {
		this.setState({
			queryKey: null
		});
		if (this.props.tag != null && this.props.tag.id == tag.id) {
			this.props.onTagChange(null);
		} else {
			this.props.onTagChange(tag);
		}
	}

	loadItems(loadPosition, direction) {
		var previousItems = 0;
		var nextItems = 0;
		if (direction > 0) nextItems = this.loadCount;
		else previousItems = this.loadCount;
		this.callCount++;
		let _callCount = this.callCount;
		DataService.getList(
			loadPosition,
			previousItems,
			nextItems,
			this.state.person ? this.state.person.id : null,
			this.state.filterList,
			this.props.tag != null ? this.props.tag.name : null,
			this.state.queryKey,
			this.props.searchQuery
		).then(result => {
			if (this.callCount != _callCount) return;
			var contentList = this.state.contentList;
			var startPos = result.listIndex - result.contentIndex;
			var arrayIndex = 0;
			contentList = contentList.map((item, i) => {
				if (i >= startPos && i < startPos + result.contentList.length) {
					var newItem = result.contentList[arrayIndex];
					arrayIndex++;
					return {
						key: result.listKeySet[i],
						loaded: true,
						data: newItem
					};
				}
				return item;
			});
			var item = contentList.find(item => item.key == this.state.query.contentKey);
			this.setState(
				{
					contentList: contentList,
					contentKey: this.state.query.contentKey,
					isLoading: false,
					lead: item.loaded ? item.data.description : null,
					leadText: item.loaded ? item.data.descriptionText : null,
					contentTagList: item.loaded ? item.data.tagList : null,
					typeCountMap: result.typeCountMap
				},
				() => this.updateSizes()
			);
			this.props.onServiceTagListChange(item.loaded ? item.data.tagList : null);
		});
	}

	toggleLeadContent() {
		if (this.state.leadOpened) {
			var textBlockHeight = this.calcMobileTextBlockHeight();
			this.detailsLeadContainer.style.height = textBlockHeight + 'px';
			this.setState({ leadOpened: false });
		} else {
			this.detailsLeadContainer.style.height = 'auto';
			this.setState({ leadOpened: true });
		}
	}

	toggleTags() {
		if (this.state.tagsOpened) {
			var textBlockHeight = this.calcMobileTextBlockHeight();
			this.scrollableTagContainer.style.height = textBlockHeight + 'px';
			this.setState({ tagsOpened: false });
		} else {
			this.scrollableTagContainer.style.height = 'auto';
			this.setState({ tagsOpened: true });
		}
	}

	render() {
		return (
			<div className="details-page">
				<div className="details-page-wrap" ref={el => (this.container = el)}>
					<div className="details-info">
						<div className="details-info-wrapper">
							{this.state.person != null ? (
								<div>
									<h1>
										{this.state.person.lastName}
										<br />
										{this.state.person.firstName}
									</h1>

									<div className="blueLine" />

									<h2>{this.state.person.profession}</h2>
								</div>
							) : null}

							<div className="details-desktop-container">
								<div className="details-desktop-content">
									<div className="details-lead-container" ref={el => (this.detailsLeadContainer = el)}>
										<div className={'details-lead-content' + (this.state.leadOpenable && !this.state.leadOpened ? ' fade-box' : '')} ref={el => (this.detailsLeadContent = el)}>
											<div className="lead" dangerouslySetInnerHTML={{ __html: this.state.lead }}></div>
										</div>
										{this.state.leadOpenable ? (
											<div className="mobile-text-open-btn" onClick={() => this.toggleLeadContent()}>
												{this.state.leadOpened ? 'Bezár' : 'Tovább'}
											</div>
										) : null}
									</div>
									<div className="scrollable-tag-container" ref={el => (this.scrollableTagContainer = el)}>
										<div className={'scrollable-tag-content' + (this.state.tagsOpenable && !this.state.tagsOpened ? ' fade-box' : '')} ref={el => (this.tagContent = el)}>
											{this.state.contentTagList != null ? (
												<div className="tag-content">
													{this.props.tag != null &&
														this.state.contentTagList.filter(t => this.props.tag.id == t.id).map(t => <TagButton key={t.id} data={t} onClick={() => this.handleTagChange(t)} active visible={true} />)}
													{(this.props.tag != null ? this.state.contentTagList.filter(t => this.props.tag.id != t.id) : this.state.contentTagList).map(t => (
														<TagButton key={t.id} data={t} onClick={() => this.handleTagChange(t)} visible={true} />
													))}
												</div>
											) : null}
										</div>
										{this.state.tagsOpenable ? (
											<div className="mobile-text-open-btn" onClick={() => this.toggleTags()}>
												{this.state.tagsOpened ? 'Bezár' : 'Tovább'}
											</div>
										) : null}
									</div>
								</div>
							</div>
							<DetailsMobileInfo
								minHeight={this.state.mobileInfoMinHeight}
								lead={this.state.lead}
								leadText={this.state.leadText}
								tagList={this.state.contentTagList}
								tag={this.props.tag}
								onTagChange={t => this.handleTagChange(t)}
							/>
						</div>
					</div>
					<div className="details-content" ref={el => (this.detailsContentContainer = el)}>
						<Slides
							history={this.props.history}
							match={this.props.match}
							isLoading={this.state.isLoading}
							items={this.state.contentList}
							mainData={this.props.mainData}
							contentKey={this.state.contentKey}
							loadItems={(i, direction) => this.loadItems(i, direction)}
							person={this.state.person}
							onFilterChange={filterList => this.setState({ filterList: filterList })}
							typeCountMap={this.state.typeCountMap}
							onChangeBottomHeight={h => (h != this.state.slideBottomHeight ? this.setState({ slideBottomHeight: h }) : null)}
						/>
					</div>
					<DetailsTimelineBar contentKey={this.state.contentKey} items={this.state.contentList} slideBottomHeight={this.state.slideBottomHeight} />
				</div>
			</div>
		);
	}
}
export default withRouter(detailsPage);
