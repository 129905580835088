import React, { Component } from 'react'

export default class TimelineBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="timeline-bar">
                <div className="timeline-bg">
                </div>
                <div className="timeline-years">
                    {this.props.data ? this.props.data.map((d) => (
                        <div className="timeline-years-item" key={d.key} style={{top: d.position+ 'px', height: this.props.itemWidth}}>
                            <div className="timeline-dateString"><span>{d.dateString}</span></div>
                            <div className="timeline-dateYear">{d.dateYear}</div>
                        </div>
                    )) : null}
                </div>
            </div>
        )
    }
}