import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import FilterButton from '../items/filterButton';
import ContentStore from './content/ContentStore';
import Menu from './header/Menu';
import PersonInfo from './header/PersonInfo';
import { Link } from 'react-router-dom';
import LogoutHeader from './header/LogoutHeader';
import SearchInput from './header/SearchInput';

class header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentTypeList: [],
			menuOpened: false,
			personId: null,
			typeCountMap: []
		};
	}

	componentDidMount() {
		if (this.props.mainData != null && this.props.defaultPersonId !== null) {
			this.updateComponent();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.mainData !== prevProps.mainData && this.props.defaultPersonId !== null) {
			this.updateComponent();
		}
	}

	updateComponent() {
		var personId = null;
		if (typeof this.props.match.params.personKey !== 'undefined') {
			if (this.props.match.params.personKey === 'mindenki') personId = 0;
			else personId = this.props.mainData.personList.find(item => item.key == this.props.match.params.personKey).id;
		} else if (this.props.defaultPersonId !== null) {
			personId = this.props.defaultPersonId;
		}
		this.setState({
			personId: personId
		});
		this.props.onPersonChange(personId);
		console.log(personId);
		let defaultContentType = null;
		if (personId > 0) {
			defaultContentType = this.props.mainData.personList.find(d => d.id === personId).defaultContentType;
		}
		this.updateContentTypeList(defaultContentType);
	}

	updateContentTypeList(defaultContentType) {
		var contentTypeList = [];
		if (ContentStore.filterList != null) {
			contentTypeList = ContentStore.filterList;
		} else {
			contentTypeList.push(defaultContentType !== null ? defaultContentType : 'Works');
		}
		this.setState(
			{
				contentTypeList: contentTypeList
			},
			() => this.props.onFilterChange(this.state.contentTypeList)
		);
	}

	handlePersonChange(personId) {
		let personKey = personId ? this.props.mainData.personList.find(item => item.id == personId).key : 'mindenki';
		this.props.history.push('/' + personKey);
		this.props.onTagChange(null);
		this.setState({
			personId: personId
		});
		this.props.onPersonChange(personId);
		console.log(personId);
		let defaultContentType = null;
		if (personId > 0) {
			defaultContentType = this.props.mainData.personList.find(item => item.id == personId).defaultContentType;
		}
		this.updateContentTypeList(defaultContentType);
	}

	handleContentTypeChange(type) {
		if (this.state.contentTypeList.indexOf(type) == -1) {
			this.setState(
				{
					contentTypeList: [...this.state.contentTypeList, type]
				},
				() => this.props.onFilterChange(this.state.contentTypeList)
			);
		} else {
			for (const [filterType, filterCount] of Object.entries(this.props.typeCountMap)) {
				if (filterCount > 0 && filterType != type && this.state.contentTypeList.indexOf(filterType) > -1) {
					console.log(filterType + ' - ' + filterCount);
					this.setState(
						{
							contentTypeList: this.state.contentTypeList.filter(item => item != type)
						},
						() => this.props.onFilterChange(this.state.contentTypeList)
					);
					break;
				}
			}
		}
	}

	getCurrentPersonKey() {
		if (typeof this.props.match.params.personKey !== 'undefined') {
			return this.props.match.params.personKey;
		}
		if (this.state.personId) {
			return this.props.mainData.personList.find(item => item.id == this.state.personId).key;
		}
		return '';
	}

	reset() {
		let defaultContentType = null;
		const personId = this.state.personId;
		if (personId != null && personId > 0) {
			defaultContentType = this.props.mainData.personList.find(item => item.id == personId).defaultContentType;
		}
		this.updateContentTypeList(defaultContentType);
		this.props.onTagChange(null);
	}

	render() {
		return (
			<div className="header">
				<div className="header-wrap">
					<Switch>
						<Route exact path="/kutato">
							<div className="header-logo-wrap">
								<Link to={{ pathname: '/' }} onClick={() => this.reset()} className="header-logo"></Link>
								<LogoutHeader />
							</div>
							<div className="person-header-info"></div>
							<div className="filter-list">
								<div className="filter-list-inner">Kutatói bejelentkezés</div>
							</div>
						</Route>
					</Switch>
					{!this.props.static ? (
						<>
							<Switch>
								<Route exact path="/:personKey?">
									<div className="header-logo-wrap">
										<Link to={{ pathname: '/' + this.getCurrentPersonKey() }} onClick={() => this.reset()} className="header-logo"></Link>
										<SearchInput searchQuery={this.props.searchQuery} onChange={q => this.props.onSearchChange(q)} />
										<LogoutHeader />
									</div>
									<div
										className="person-select"
										style={{
											minWidth: window.matchMedia('(min-width: 992px)').matches ? this.props.itemWidth + 15 : '0'
										}}
									>
										{this.props.mainData != null ? (
											<select className="select" onChange={e => this.handlePersonChange(e.target.value || null)}>
												<option value="" selected={this.getCurrentPersonKey() === null}>
													Alkotó kiválasztása
												</option>
												{this.props.mainData.personList.map((p, i) => (
													<option key={p.id} value={p.id} selected={this.getCurrentPersonKey() == p.key}>
														{p.name + (p.profession != null ? ' - ' + p.profession : '')}
													</option>
												))}
											</select>
										) : null}
									</div>
								</Route>
								<Route path="/reszletek/:personKey/:contentKey">
									<div className="header-logo-wrap">
										<Link to={{ pathname: '/' + this.getCurrentPersonKey(), state: { contentKey: this.props.match.params.contentKey } }} className="header-logo"></Link>
										<SearchInput searchQuery={this.props.searchQuery} onChange={q => this.props.onSearchChange(q)} />
										<LogoutHeader />
									</div>
									<PersonInfo mainData={this.props.mainData} />
								</Route>
							</Switch>
							<Switch>
								<Route exact path="/:personKey?">
									<div className="filter-list">
										<div className="filter-list-inner">
											{this.props.mainData && this.props.typeCountMap != null
												? this.props.mainData.contentTypeSelection.items.map(item =>
														this.props.typeCountMap[item.value] > 0 ? (
															<FilterButton key={item.value} item={item} active={this.state.contentTypeList.indexOf(item.value) > -1} onChange={t => this.handleContentTypeChange(t)} />
														) : null
												  )
												: 'Betöltés alatt ...'}
										</div>
									</div>
								</Route>
							</Switch>
						</>
					) : null}
					<div className="menu-icon-box">
						<div className="menu-icon" onClick={() => this.setState({ menuOpened: !this.state.menuOpened })}></div>
					</div>
					<Menu
						open={this.state.menuOpened}
						mainData={this.props.mainData}
						personId={this.state.personId}
						tag={this.props.tag}
						onTagChange={tag => {
							this.props.onTagChange(tag);
							this.setState({ menuOpened: false });
						}}
						serviceTagList={this.props.serviceTagList}
						onLogout={tag => {
							this.setState({ menuOpened: false });
						}}
						searchQuery={this.props.searchQuery}
						onSearchChange={q => this.props.onSearchChange(q)}
					/>
				</div>
			</div>
		);
	}
}

export default withRouter(header);
