import React, { Component } from 'react'

export default class DetailsButton extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <a className='details-button' href={this.props.link} target={this.props.newTab ? '_blank' : '_self'}>
                <div className="orb">
                    <div className="iconWrap">
                        <img className="icon normal" src={this.props.iconVariant} />
                        <img className="icon hover" src={this.props.iconVariant} />
                    </div>
                </div>
                <div className='label'>{this.props.label}</div>
            </a>
        )
    }
}