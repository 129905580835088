import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Header from './components/header';
import MainPage from './pages/mainPage';
import DetailsPage from './pages/detailsPage';

import JsonData from './config.json';
import MainService from './service/MainService';
import nBossConnection from './service/nBossConnection';
import ResearcherLoginPage from './pages/researcherLoginPage';
import ResearcherProvider from './components/provider/ResearcherProvider';

//import "react-awesome-lightbox/build/style.css";

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultPersonId: null,
			personId: null,
			mainData: null,
			filterList: [],
			itemWidth: null,
			tag: null,
			typeCountMap: null,
			serviceTagList: [],
			searchQuery: ''
		};
	}

	getConfigData() {
		this.setState({ configData: JsonData });
	}

	componentDidMount() {
		nBossConnection.setErrorHandler(error => {
			console.log('global error handler: ');
			console.log(error);
			if (typeof error.response.status !== 'undefined' && error.response.status == 500) alert('Internal Server Error!');
			else alert(error);
		});
		this.getConfigData();
		MainService.getData().then(result => {
			this.setState({ mainData: result, defaultPersonId: result.propertiesMap.defaultPersonId.value });
		});
	}

	render() {
		return (
			<Router>
				<ResearcherProvider>
					<Switch>
						<Route path="/kutato">
							<Header
								mainData={this.state.mainData}
								onPersonChange={personId => this.setState({ personId: personId })}
								onFilterChange={filterList => this.setState({ filterList: filterList })}
								itemWidth={this.state.itemWidth}
								onTagChange={tag => this.setState({ tag: tag })}
								tag={this.state.tag}
								serviceTagList={this.state.serviceTagList}
								static
								searchQuery={this.state.searchQuery}
								onSearchChange={searchQuery => this.setState({ searchQuery: searchQuery })}
							/>
							<ResearcherLoginPage />
						</Route>
						<Route exact path="/:personKey?">
							<Header
								defaultPersonId={this.state.defaultPersonId}
								mainData={this.state.mainData}
								onPersonChange={personId => this.setState({ personId: personId })}
								onFilterChange={filterList => this.setState({ filterList: filterList })}
								itemWidth={this.state.itemWidth}
								typeCountMap={this.state.typeCountMap}
								onTagChange={tag => this.setState({ tag: tag })}
								tag={this.state.tag}
								serviceTagList={this.state.serviceTagList}
								personId={this.state.personId}
								searchQuery={this.state.searchQuery}
								onSearchChange={searchQuery => this.setState({ searchQuery: searchQuery })}
							/>
							{this.state.mainData ? (
								<MainPage
									mainData={this.state.mainData}
									personId={this.state.personId}
									filterList={this.state.filterList}
									tag={this.state.tag}
									onItemWidthChanged={w => this.setState({ itemWidth: w })}
									onTagChange={tag => this.setState({ tag: tag })}
									onTypeCountMap={typeCountMap => this.setState({ typeCountMap: typeCountMap })}
									serviceTagList={this.state.serviceTagList}
									onServiceTagListChange={tagList => this.setState({ serviceTagList: tagList })}
									searchQuery={this.state.searchQuery}
								/>
							) : null}
						</Route>
						<Route path="/reszletek/:personKey/:contentKey">
							<Header
								mainData={this.state.mainData}
								onPersonChange={personId => this.setState({ personId: personId })}
								onFilterChange={filterList => this.setState({ filterList: filterList })}
								itemWidth={this.state.itemWidth}
								onTagChange={tag => this.setState({ tag: tag })}
								tag={this.state.tag}
								serviceTagList={this.state.serviceTagList}
								searchQuery={this.state.searchQuery}
								onSearchChange={searchQuery => this.setState({ searchQuery: searchQuery })}
							/>
							{this.state.mainData ? (
								<DetailsPage
									mainData={this.state.mainData}
									tag={this.state.tag}
									onTagChange={tag => this.setState({ tag: tag })}
									onServiceTagListChange={tagList => this.setState({ serviceTagList: tagList })}
									searchQuery={this.state.searchQuery}
								/>
							) : null}
						</Route>
					</Switch>
				</ResearcherProvider>
			</Router>
		);
	}
}
