import { useResearcher } from '../provider/ResearcherProvider';

function LogoutHeader(props) {
	const { isLoggedIn, handleLogout } = useResearcher();

	if (isLoggedIn) {
		return (
			<div className="filter-button active logout-header-button" onClick={() => handleLogout()}>
				<div className="orb">
					<div className="iconWrap">
						<img className="icon white" src="img/icons/turn-off.svg" />
					</div>
				</div>
				<div className="label">Kijelentkezés</div>
			</div>
		);
	}
	return <></>;
}

export default LogoutHeader;
