import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PersonInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			person: null
		};
	}

	componentDidMount() {
		if (this.props.match.params.personKey != null && this.props.mainData != null) {
			this.updatePerson();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.personKey != null && this.props.mainData != null) {
			this.updatePerson();
		}
	}

	updatePerson() {
		if (this.props.match.params.personKey === 'mindenki') {
			if (this.state.person !== null)
				this.setState({
					person: null
				});
		} else {
			var p = this.props.mainData.personList.find(i => i.key == this.props.match.params.personKey);
			if (this.state.person == null || this.state.person.id != p.id)
				this.setState({
					person: this.props.mainData.personList.find(i => i.key == this.props.match.params.personKey)
				});
		}
	}

	render() {
		return (
			<div className="person-header-info">
				{this.state.person != null ? (
					<div>
						<div className="last-name">{this.state.person.lastName}</div>
						<div className="first-name">{this.state.person.firstName}</div>
						<div className="profession">{this.state.person.profession}</div>
					</div>
				) : null}
			</div>
		);
	}
}
export default withRouter(PersonInfo);
