import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import ResearcherLogin from '../service/ResearcherLogin';
import { useResearcher } from '../components/provider/ResearcherProvider';

function ResearcherLoginPage(props) {
	const history = useHistory();
	const { setIsLoggedIn } = useResearcher();

	const { register, handleSubmit, formState } = useForm();
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const onSubmit = data => {
		if (data.email && data.password) {
			setLoading(true);
			ResearcherLogin.login(data.email, data.password).then(resp => {
				setLoading(false);
				if (resp) {
					setIsLoggedIn(true);
					history.push('/');
				} else {
					setErrorMessage('Hibás e-mail cím vagy jelszó.');
				}
				console.log(resp);
			});
		} else {
			setErrorMessage('Minden adat megadása kötelező.');
		}
		console.log(data);
	};

	useEffect(() => {
		setErrorMessage('');
	}, [formState.touchedFields]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="main-page login-page">
				<div className="login-box">
					<div className="login-box-header">Bejelentkezési adatok</div>
					<div className="login-box-content">
						<label htmlFor="email">E-mail cím:</label>
						<input type="text" name="email" id="email" {...register('email')} />
						<label htmlFor="password">Jelszó:</label>
						<input type="password" name="password" id="password" {...register('password')} />
						{errorMessage && <p className="login-error">{errorMessage}</p>}
						<input type="submit" value="Bejelentkezés" disabled={loading} />
					</div>
				</div>
			</div>
		</form>
	);
}

export default ResearcherLoginPage;
