import DataContentResultDto from '../dto/content/DataContentResultDto';
import nBossConnection from './nBossConnection';

class DataService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'dataControl';
	}

	/**
	 * @param {number} index
	 * @param {number} previousItems
	 * @param {number} nextItems
	 * @param {number} personId
	 * @param {Array.<string>} contentType
	 * @param {string} tag
	 * @param {string} key
	 * @param {string} searchQuery
	 */
	/**
	 * @return {Promise<DataContentResultDto>}
	 */
	getList(index, previousItems, nextItems, personId, contentType, tag, key, searchQuery) {
		return new Promise((resolve, reject) => {
			this.connection
				.post('/' + this.CONTROL + '/getList', {
					index: index,
					previousItems: previousItems,
					nextItems: nextItems,
					personId: personId,
					contentType: contentType,
					tag: tag,
					key: key,
					searchQuery
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} content
	 * @param {number} previousItems
	 * @param {number} nextItems
	 * @param {number} personId
	 * @param {Array.<string>} contentType
	 * @param {string} tag
	 * @param {string} key
	 * @param {string} searchQuery
	 */
	/**
	 * @return {Promise<DataContentResultDto>}
	 */
	getListByContent(content, previousItems, nextItems, personId, contentType, tag, key, searchQuery) {
		return new Promise((resolve, reject) => {
			this.connection
				.post('/' + this.CONTROL + '/getList', {
					content: content,
					previousItems: previousItems,
					nextItems: nextItems,
					personId: personId,
					contentType: contentType,
					tag: tag,
					key: key,
					searchQuery
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new DataService();
