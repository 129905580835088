import React, { Component } from 'react';
import { isElementOfType } from 'react-dom/test-utils';
import { Link } from 'react-router-dom';
import nBossConnection from '../../service/nBossConnection';

export default class ContentItem extends Component {
	constructor(props) {
		super(props);
		this.container = null;
		this.descriptionWrapperContainer = null;
		this.descriptionContainer = null;
		this.resizeObserver = null;
		this.visibleObserver = null;
		this.activated = false;
	}

	componentDidMount() {
		this.resizeObserver = new ResizeObserver(entries => {
			var el = entries[0].target;
			var size = el.getBoundingClientRect().width;
			el.style.height = size + 'px';
			if (size > 0 && this.props.activate && !this.activated) {
				var elRect = el.getBoundingClientRect();
				this.activated = true;
				window.scrollTo(0, elRect.top + size / 2 - window.innerHeight / 2);
			}
		});
		this.resizeObserver.observe(this.container);
		this.visibleObserver = new IntersectionObserver(entries => {
			if (!this.props.item.loaded && entries[0].isIntersecting /* && entries[0].target.offsetWidth == entries[0].target.offsetHeight*/) {
				this.props.onNeedLoad();
			}
		});
		this.visibleObserver.observe(this.container, {
			threshold: 1.0
		});
	}

	componentWillUnmount() {
		if (this.resizeObserver != null) this.resizeObserver.unobserve(this.container);
		if (this.visibleObserver != null) this.visibleObserver.unobserve(this.container);
	}

	changeDescMaxHeight(opt) {
		if (this.descriptionWrapperContainer != null && this.descriptionContainer) {
			if (opt) {
				this.descriptionWrapperContainer.style.maxHeight = this.descriptionContainer.offsetHeight + 'px';
			} else {
				this.descriptionWrapperContainer.style.maxHeight = '0px';
			}
		}
	}

	getContentImageStyle() {
		if (this.props.item.loaded) {
			if (this.props.item.data.mediaType == 'Text') {
				return {
					backgroundImage: 'url("' + process.env.PUBLIC_URL + '/img/textmedia_bg.png")'
				};
			} else if (this.props.item.data.mediaType == 'Image') {
				if (this.props.item.data.mainPageImage != null) {
					return {
						backgroundImage: 'url("' + nBossConnection.baseUrl + 'imageRepository/getImageBySize?size=MAIN&key=' + this.props.item.data.mainPageImage.key + '")'
					};
				} else if (this.props.item.data.image != null) {
					return {
						backgroundImage: 'url("' + nBossConnection.baseUrl + 'imageRepository/getImageBySize?size=MAIN&key=' + this.props.item.data.image.key + '")'
					};
				}
			} else if (this.props.item.data.mediaType == 'Video') {
				if (this.props.item.data.mainPageImage != null) {
					return {
						backgroundImage: 'url("' + nBossConnection.baseUrl + 'imageRepository/getImageBySize?size=MAIN&key=' + this.props.item.data.mainPageImage.key + '")'
					};
				}
				return {
					backgroundImage: 'url("http://i3.ytimg.com/vi/' + this.props.item.data.videoYoutubeId + '/hqdefault.jpg")'
				};
			}
		} else {
			return {
				backgroundImage: 'url("/img/spinner.gif")',
				backgroundSize: 'auto'
			};
		}
	}

	getVideoIdFromUrl(videoYoutubeId) {
		if (videoYoutubeId.indexOf('youtu.be') > -1) {
			var video_id = videoYoutubeId.split('/')[3];
			var nextPer = video_id.indexOf('/');
			if (nextPer != -1) {
				video_id = video_id.substring(0, nextPer);
			}
			return video_id;
		} else if (videoYoutubeId.indexOf('v=') > -1) {
			var video_id = videoYoutubeId.split('v=')[1];
			var ampersandPosition = video_id.indexOf('&');
			if (ampersandPosition != -1) {
				video_id = video_id.substring(0, ampersandPosition);
			}
			return video_id;
		}
	}

	render() {
		return (
			<div className="content-item" data-key={this.props.item.key} ref={el => (this.container = el)} onMouseEnter={() => this.changeDescMaxHeight(true)} onMouseLeave={() => this.changeDescMaxHeight(false)}>
				<Link to={'/reszletek/' + (this.props.person && this.props.person.key ? this.props.person.key : 'mindenki') + '/' + this.props.item.key} onClick={() => this.props.onSelect()}>
					<div className="content-item-image" style={this.getContentImageStyle()}>
						{this.props.item.loaded && this.props.item.data.mediaType == 'Text' ? (
							<div className="content-item-text">
								<div className="content-item-text-quote-top">&nbsp;</div>
								<div className="content-item-text-content" dangerouslySetInnerHTML={{ __html: this.props.item.data.textMain }} />
								<div className="content-item-text-quote-btm">&nbsp;</div>
							</div>
						) : null}
						{this.props.item.loaded && this.props.item.data.mediaType == 'Video' ? <div className="content-item-video">&nbsp;</div> : null}
					</div>
					{this.props.item.loaded ? (
						<div className="content-item-label">
							<div className="content-item-year">{this.props.item.data.dateString}</div>
							<div className="content-item-desc-wrapper" ref={el => (this.descriptionWrapperContainer = el)}>
								<div className="content-item-desc" ref={el => (this.descriptionContainer = el)}>
									{this.props.item.data.title}
								</div>
							</div>
						</div>
					) : null}
				</Link>
			</div>
		);
	}
}
