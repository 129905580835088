import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import TagButton from './TagButton';

class DetailsMobileInfo extends Component {
	constructor(props) {
		super(props);
		this.leadContent = null;
		this.leadCollapseContent = null;
		this.tagsContent = null;
		this.tagsCollapseContent = null;
		this.leadMeasureDiv = null;
		this.tagsMeasureDiv = null;
		this.state = {
			leadCollapseText: null,
			tagsCollapseList: null,
			leadOpened: true,
			leadOpenable: false,
			tagsOpened: true,
			tagsOpenable: false,
			measurementMode: true,
			leadCollapsedHeight: null,
			tagsCollapsedHeight: null,
			leadCount: null,
			tagCount: null
		};
	}

	componentDidMount() {
		if (this.props.minHeight > 0 && this.props.lead != null && this.props.tagList != null) {
			this.updateLayout();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.minHeight > 0 && this.props.lead != null && this.props.tagList != null && (this.props.minHeight != prevProps.minHeight || this.props.lead != prevProps.lead || this.props.tagList != prevProps.tagList)) {
			this.updateLayout();
		} else if ((this.state.leadCollapsedHeight != prevState.leadCollapsedHeight || this.state.tagsCollapsedHeight != prevState.tagsCollapsedHeight) && this.state.tagsCollapsedHeight == null && this.state.leadCollapsedHeight == null) {
			this.setState({
				measurementMode: false
			});
		}
	}

	updateLayout() {
		this.setState(
			{
				height: 0,
				measurementMode: true
			},
			() => {
				var leadRect = this.leadContent.getBoundingClientRect();
				var tagsRect = this.tagsContent.getBoundingClientRect();
				if (leadRect.height + tagsRect.height <= this.props.minHeight) {
					this.setState({
						height: this.props.minHeight,
						measurementMode: false,
						leadOpened: true,
						leadOpenable: false,
						tagsOpened: true,
						tagsOpenable: false
					});
				} else {
					var halfHeight = this.props.minHeight / 2;
					var leadOpenable = true;
					var tagsOpenable = true;
					var leadCollapsedHeight = null;
					var tagsCollapsedHeight = null;
					if (leadRect.height <= halfHeight) {
						leadOpenable = false;
						tagsCollapsedHeight = this.props.minHeight - leadRect.height;
					} else if (tagsRect.height <= halfHeight) {
						tagsOpenable = false;
						leadCollapsedHeight = this.props.minHeight - tagsRect.height;
					} else {
						leadCollapsedHeight = halfHeight;
						tagsCollapsedHeight = halfHeight;
					}
					if (leadCollapsedHeight != null) {
						this.setState(
							{
								leadCollapseText: null,
								leadCollapsedHeight: leadCollapsedHeight
							},
							() => {
								this.measureLeadCollapse(1, leadCollapsedHeight);
							}
						);
					}
					if (tagsCollapsedHeight != null) {
						this.setState(
							{
								tagsCollapseList: null,
								tagsCollapsedHeight: tagsCollapsedHeight
							},
							() => {
								this.measureTagsCollapse(1, tagsCollapsedHeight);
							}
						);
					}
				}
			}
		);
	}

	measureLeadCollapse(count, maxHeight) {
		var html = '<p>' + this.getLeadCollapseText(count) + '<span class="readmore">tovább...</span></p>';
		this.leadMeasureDiv.innerHTML = html;
		var rect = this.leadMeasureDiv.getBoundingClientRect();
		if (rect.height > maxHeight || count >= this.props.leadText.split(' ').length - 1) {
			this.leadMeasureDiv.innerHTML = '';
			this.setState({
				leadCollapseText: this.getLeadCollapseText(count - 1),
				leadCollapsedHeight: null,
				leadOpenable: true,
				leadOpened: false
			});
		} else {
			this.measureLeadCollapse(count + 1, maxHeight);
		}
	}

	getLeadCollapseText(count) {
		var words = this.props.leadText.split(' ', count);
		var result = words.join(' ');
		return result;
	}

	measureTagsCollapse(count, maxHeight) {
		var html = this.getTagsCollapseHtml(count) + '<span class="readmore">tovább...</span>';
		this.tagsMeasureDiv.innerHTML = html;
		var rect = this.tagsMeasureDiv.getBoundingClientRect();
		if (rect.height > maxHeight || count >= this.props.content) {
			this.tagsMeasureDiv.innerHTML = '';
			this.setState({
				tagsCollapseList: this.props.tagList.slice(0, count - 1),
				tagsCollapsedHeight: null,
				tagsOpenable: true,
				tagsOpened: false
			});
		} else {
			this.measureTagsCollapse(count + 1, maxHeight);
		}
	}

	getTagsCollapseHtml(count) {
		var html = '';
		for (var i = 0; i < count; i++) {
			var item = this.props.tagList[i];
			html += '<div class="hashtag">#' + item.name + '</div>';
		}
		return html;
	}

	render() {
		return (
			<div className="details-mobile-container" style={{ height: this.state.measurementMode ? 0 : 'auto', minHeight: this.state.measurementMode ? 0 : this.props.minHeight }}>
				<div className="details-mobile-lead lead" ref={el => (this.leadMeasureDiv = el)} style={{ display: this.state.measurementMode ? 'block' : 'none' }}></div>
				<div className="details-mobile-tags" ref={el => (this.tagsMeasureDiv = el)} style={{ display: this.state.measurementMode ? 'block' : 'none' }}></div>
				{this.state.leadCollapseText != null ? (
					<div className="details-mobile-lead lead" ref={el => (this.leadCollapseContent = el)} style={{ display: (this.state.leadOpenable && !this.state.leadOpened) || this.state.measurementMode ? 'block' : 'none' }}>
						<p>
							{this.state.leadCollapseText}{' '}
							<span className="readmore" onClick={() => this.setState({ leadOpened: true })}>
								tovább...
							</span>
						</p>
					</div>
				) : null}
				{this.state.tagsCollapseList != null ? (
					<div className="details-mobile-tags" ref={el => (this.tagsCollapseContent = el)} style={{ display: (this.state.tagsOpenable && !this.state.tagsOpened) || this.state.measurementMode ? 'block' : 'none' }}>
						{this.state.tagsCollapseList.map(t => (
							<TagButton key={t.id} data={t} onClick={() => this.props.onTagChange(t)} active={this.props.tag != null && this.props.tag.id == t.id} visible />
						))}
						<span className="readmore" onClick={() => this.setState({ tagsOpened: true })}>
							tovább...
						</span>
					</div>
				) : null}
				{this.props.lead != null ? (
					<div
						className="details-mobile-lead lead"
						dangerouslySetInnerHTML={{ __html: this.props.lead }}
						ref={el => (this.leadContent = el)}
						style={{ display: this.state.leadOpened || this.state.measurementMode ? 'block' : 'none' }}
					></div>
				) : null}
				{this.props.tagList != null ? (
					<div className="details-mobile-tags" ref={el => (this.tagsContent = el)} style={{ display: this.state.tagsOpened || this.state.measurementMode ? 'block' : 'none' }}>
						{this.props.tagList.map(t => (
							<TagButton key={t.id} data={t} onClick={() => this.props.onTagChange(t)} active={this.props.tag != null && this.props.tag.id == t.id} visible />
						))}
					</div>
				) : null}
			</div>
		);
	}
}

export default DetailsMobileInfo;
