import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class footer extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		let options = {
			root: document,
			rootMargin: '0px',
			threshold: 0
		};
		let observer = new IntersectionObserver(e => {
			this.props.onHandleFooterVisible(e[0].isIntersecting);
		}, options);
		observer.observe(this.container);
	}

	render() {
		return (
			<div ref={el => (this.container = el)}>
				<Container fluid className="footer" style={{ marginTop: '15px' }}>
					<div className="footer-left">&nbsp;</div>
					<div className="footer-wrap">
						<div className="impressum">Impresszum</div>
						<div className="blueLine" />
						<div className="footer-texts">
							<a className="footer-email" href={this.props.mainData != null ? 'mailto: ' + this.props.mainData.propertiesMap['impressum-email'].value : null}>
								{this.props.mainData != null ? this.props.mainData.propertiesMap['impressum-email'].value : null}
							</a>
							<div className="copyright" dangerouslySetInnerHTML={{ __html: this.props.mainData != null ? this.props.mainData.propertiesMap['impressum-copyright'].value : null }} />
						</div>
					</div>
				</Container>
			</div>
		);
	}
}
