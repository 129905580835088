import React, { Component } from 'react';
import DetailsButton from '../../items/DetailsButton';
import nBossConnection from '../../service/nBossConnection';
import FilterButton from '../../items/filterButton';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Lightbox from 'react-awesome-lightbox';

export class Slides extends Component {
	constructor(props) {
		super(props);
		this.mainContainer = null;
		this.slidesContainer = null;
		this.loadSensitivity = 5;
		this.state = {
			slidePos: 0,
			itemSpace: 0,
			transitionEnabled: true,
			contentTypeList: [],
			currentContentKey: null,
			videoPlaying: null,
			lightBoxUrl: null,
			lightBoxTitle: null,
			buttonsPaddingRight: 0
		};
	}

	componentDidMount() {
		var resizeObserver = new ResizeObserver(entries => {
			if (this.props.items != null) this.updateSize();
		});
		resizeObserver.observe(this.slideItemContainer);
		resizeObserver.observe(this.mainContainer);
		if (this.props.items != null) {
			this.updateSize();
			if (this.props.contentKey != null) {
				var contentPos = this.props.items.indexOf(this.props.items.find(item => item.key == this.props.contentKey));
				var diff = Math.abs(this.state.slidePos - contentPos);
				this.setState({
					slidePos: contentPos,
					transitionEnabled: diff < 2,
					currentContentKey: this.props.items[contentPos].key
				});
			}
		}
		if (this.props.mainData != null) {
			this.updateContentTypeList();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.items != null) {
			this.updateSize();
			/*if( this.props.contentKey != null && 
				(prevProps.contentKey == null || this.props.contentKey != prevProps.contentKey) ) {*/
			var contentPos = this.props.items.indexOf(this.props.items.find(item => item.key == this.props.contentKey));
			var diff = Math.abs(this.state.slidePos - contentPos);
			if (contentPos != this.state.slidePos) {
				this.setState({
					slidePos: contentPos,
					transitionEnabled: diff < 2,
					currentContentKey: this.props.items[contentPos].key
				});
			}
			//}
			if (!this.props.isLoading && prevState.slidePos != this.state.slidePos && Math.abs(this.state.slidePos - prevState.slidePos) == 1) {
				this.checkUnloadedItems(prevState.slidePos);
			}
		}
		if (this.props.mainData !== prevProps.mainData) {
			this.updateContentTypeList();
		}
	}

	updateContentTypeList() {
		var contentTypeList = [];
		for (var i = 0; i < this.props.mainData.contentTypeSelection.items.length; i++) {
			contentTypeList.push(this.props.mainData.contentTypeSelection.items[i].value);
		}
		this.setState(
			{
				contentTypeList: contentTypeList
			},
			() => this.props.onFilterChange(this.state.contentTypeList)
		);
	}

	updateSize() {
		if (this.mainContainer != null && this.slidesContainer != null) {
			var containerWidth = this.slidesContainer.offsetWidth;
			var itemContainerWidth = this.props.items.length * this.mainContainer.offsetWidth + (this.props.items.length - 1) * this.state.itemSpace;
			this.slideItemContainer.style.width = itemContainerWidth + 'px';
			//this.slideBottomItemContainer.style.width = itemContainerWidth + "px";
			//this.slidesContainer.style.height = this.slideItemContainer.offsetHeight + "px";
			this.slidesBottomContainer.style.height = this.slideBottomItemContainer.offsetHeight + 'px';
			this.props.onChangeBottomHeight(this.slideBottomItemContainer.offsetHeight);
			/*if( containerWidth >= itemContainerWidth ) {
				this.slideLeftBtn.style.opacity = "0.00001";
				this.slideRightBtn.style.opacity = "0.00001";
				this.slideItemContainer.style.position = "static";
				this.slidesContainer.style.justifyContent = "center";
			}*/
			const buttonsPaddingRight = window.matchMedia('(min-width: 992px)').matches ? this.getAvailableTypeCount() * 57 + 12 : 0;
			if (this.state.buttonsPaddingRight !== buttonsPaddingRight)
				this.setState({
					buttonsPaddingRight: buttonsPaddingRight
				});

			this.updatePos();
		}
	}

	calcPosOffset(pos) {
		return (pos * this.mainContainer.offsetWidth + pos * this.state.itemSpace) * -1;
	}

	updatePos() {
		this.slideItemContainer.style.left = this.calcPosOffset(this.state.slidePos) + 'px';
		//this.slideBottomItemContainer.style.left = this.calcPosOffset(this.state.slidePos) + "px";
		if (this.state.slidePos == 0) this.slideLeftBtn.style.opacity = '0.00001';
		else this.slideLeftBtn.style.opacity = '1';
		if (this.state.slidePos < this.props.items.length - 1) this.slideRightBtn.style.opacity = '1';
		else this.slideRightBtn.style.opacity = '0.00001';
	}

	slideLeft() {
		if (this.state.slidePos > 0) {
			var contentPos = this.props.items.indexOf(this.props.items.find(item => item.key == this.props.contentKey));
			contentPos--;
			var currentContentKey = this.props.items[contentPos].key;
			var newState = '/reszletek/' + this.props.match.params.personKey + '/' + currentContentKey;
			if (this.props.history.location.pathname != newState) this.props.history.push(newState);
			this.setState({
				currentContentKey: currentContentKey,
				videoPlaying: null
			});
		}
	}

	slideRight() {
		var containerWidth = this.slidesContainer.offsetWidth;
		var itemContainerWidth = this.slideItemContainer.offsetWidth;
		if (this.state.slidePos < this.props.items.length - 1 && itemContainerWidth + this.calcPosOffset(this.state.slidePos) > containerWidth) {
			var contentPos = this.props.items.indexOf(this.props.items.find(item => item.key == this.props.contentKey));
			contentPos++;
			var currentContentKey = this.props.items[contentPos].key;
			var newState = '/reszletek/' + this.props.match.params.personKey + '/' + currentContentKey;
			if (this.props.history.location.pathname != newState) this.props.history.push(newState);
			this.setState({
				currentContentKey: currentContentKey,
				videoPlaying: null
			});
		}
	}

	getSlideImageStyle(item) {
		const mobileMatch = window.matchMedia('(max-width: 576px)');
		const mobileLandscapeMatch = window.matchMedia('(max-width: 992px) and (orientation: landscape)');
		if (item.loaded) {
			if (item.data.image != null)
				return {
					backgroundImage: 'url("' + nBossConnection.baseUrl + 'imageRepository/getImageBySize?size=' + (mobileMatch.matches || mobileLandscapeMatch.matches ? 'DETAILSCROP' : 'DETAILS') + '&key=' + item.data.image.key + '")'
				};
			return {};
		}
		return {
			backgroundImage: 'url("img/spinner.gif")',
			backgroundSize: 'auto'
		};
	}

	getImageUrl(item) {
		if (item.loaded && item.data.image != null) {
			return nBossConnection.baseUrl + 'imageRepository/getImageBySize?size=ORIGINAL&key=' + item.data.image.key;
		}
		return null;
	}

	getItemPersonList(item) {
		var itemPersonList = [];
		for (var i = 0; i < item.data.personList.length; i++) {
			var personId = item.data.personList[i];
			const personData = this.props.mainData.personList.find(p => p.id == personId);
			if (personData) itemPersonList.push(personData.name);
		}
		return itemPersonList.length > 0 ? itemPersonList.join(', ') : null;
	}

	checkUnloadedItems(prevPos) {
		var direction = this.state.slidePos - prevPos;
		var checkCount = this.loadSensitivity;
		for (var i = this.state.slidePos + direction; i > -1 && i < this.props.items.length; i = i + direction) {
			if (!this.props.items[i].loaded) {
				this.props.loadItems(i, direction);
				break;
			}
			checkCount--;
			if (checkCount == 0) break;
		}
	}

	handleContentTypeChange(type) {
		if (this.state.contentTypeList.indexOf(type) == -1) {
			this.setState(
				{
					contentTypeList: [...this.state.contentTypeList, type]
				},
				() => this.props.onFilterChange(this.state.contentTypeList)
			);
		} else {
			for (const [filterType, filterCount] of Object.entries(this.props.typeCountMap)) {
				if (filterCount > 0 && filterType != type && this.state.contentTypeList.indexOf(filterType) > -1) {
					console.log(filterType + ' - ' + filterCount);
					this.setState(
						{
							contentTypeList: this.state.contentTypeList.filter(item => item != type)
						},
						() => this.props.onFilterChange(this.state.contentTypeList)
					);
					break;
				}
			}
		}
	}

	getAvailableTypeCount() {
		let result = 0;
		if (this.props.typeCountMap) {
			for (const [filterType, filterCount] of Object.entries(this.props.typeCountMap)) {
				if (filterCount > 0) result++;
			}
		}
		return result;
	}

	render() {
		return (
			<div className="slides-container" ref={el => (this.mainContainer = el)}>
				<div className="slides-frame">
					<div className="slides" ref={el => (this.slidesContainer = el)}>
						<div className={'slide-item-container' + (this.state.transitionEnabled ? ' slide-transition' : '')} ref={el => (this.slideItemContainer = el)}>
							{this.props.items != null
								? this.props.items.map(i => (
										<div key={i.key} className="slide-item" data-key={i.key}>
											{i.loaded && i.data.mediaType == 'Text' ? (
												<div className="slide-item-text-container">
													<div className="slide-item-text-scroll-wrapper">
														<div className="slide-item-text-wrapper" dangerouslySetInnerHTML={{ __html: i.data.textHtml }}></div>
													</div>
												</div>
											) : null}
											{i.loaded && i.data.mediaType == 'Video' ? (
												<ReactPlayer
													url={'https://youtube.com/watch?v=' + i.data.videoYoutubeId}
													controls={true}
													width="100%"
													height="100%"
													playing={this.state.videoPlaying == i.key}
													onPlay={() => this.setState({ videoPlaying: i.key })}
												/>
											) : null}
											{!i.loaded || i.data.mediaType == 'Image' ? (
												<div className="slide-item-image" style={this.getSlideImageStyle(i)} onClick={() => this.setState({ lightBoxUrl: this.getImageUrl(i), lightBoxTitle: i.loaded ? i.data.title : null })}></div>
											) : null}
										</div>
								  ))
								: null}
						</div>
						<div className="slide-left" ref={el => (this.slideLeftBtn = el)} onClick={() => this.slideLeft()}>
							<div></div>
						</div>
						<div className="slide-right" ref={el => (this.slideRightBtn = el)} onClick={() => this.slideRight()}>
							<div></div>
						</div>
					</div>
					<Link to={{ pathname: '/' + this.props.match.params.personKey, state: { contentKey: this.state.currentContentKey } }} className="close-details"></Link>
				</div>
				<div className="slides-bottom" ref={el => (this.slidesBottomContainer = el)}>
					<div className="slide-bottom-item-container" ref={el => (this.slideBottomItemContainer = el)} style={{ paddingRight: this.state.buttonsPaddingRight + 'px' }}>
						{this.props.items != null
							? this.props.items.map((i, ii) => (
									<div
										key={i.key}
										className={'slide-item-bottom' + (this.state.transitionEnabled ? ' slide-transition' : '') + (this.state.slidePos == ii ? ' slide-item-bottom-active' : '')}
										style={{ paddingRight: this.state.buttonsPaddingRight + 'px' }}
									>
										<div className="slide-item-desc">
											<div className="slide-item-year">{i.loaded ? i.data.dateString : null}</div>
											<div className="slide-item-text">
												{i.loaded ? i.data.title : null}
												<br />
												{i.loaded ? this.getItemPersonList(i) : null}
											</div>
										</div>
										{i.loaded && i.data.locationLatitude != null && i.data.locationLongitude != null ? (
											<DetailsButton
												iconVariant="img/location_icon.svg"
												label="Térkép"
												link={'https://www.google.com/maps/search/?api=1&query=' + i.data.locationLatitude + ',' + i.data.locationLongitude}
												newTab={true}
											></DetailsButton>
										) : null}
										{i.loaded && i.data.pdf != null ? (
											<DetailsButton iconVariant="img/pdf_icon.svg" label="PDF" link={nBossConnection.baseUrl + 'fileRepository/getFile?key=' + i.data.pdf.key} newTab={true}></DetailsButton>
										) : null}
									</div>
							  ))
							: null}
					</div>
					<div className="filter-list">
						<div className="filter-list-inner">
							{this.props.mainData != null && this.props.typeCountMap != null
								? this.props.mainData.contentTypeSelection.items.map(item =>
										this.props.typeCountMap[item.value] > 0 ? (
											<FilterButton
												key={item.value}
												item={item}
												active={this.state.contentTypeList.indexOf(item.value) > -1}
												count={this.props.typeCountMap[item.value]}
												onChange={t => this.handleContentTypeChange(t)}
											/>
										) : null
								  )
								: null}
						</div>
					</div>
				</div>
				<Lightbox image={this.state.lightBoxUrl} title={this.state.lightBoxTitle} onClose={() => this.setState({ lightBoxUrl: null, lightBoxTitle: null })} />
			</div>
		);
	}
}

export default Slides;
