import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContentTimeline from '../components/content/ContentTimeline';
import Footer from '../components/footer';
import TagService from '../service/TagService';
import TagButton from '../items/TagButton';
import { withRouter } from 'react-router-dom';

class mainPage extends Component {
	constructor(props) {
		super(props);
		this.onWindowResize = this.updateSizes.bind(this);
		this.state = {
			person: null,
			personTagList: null,
			bottomMargin: 15
		};
	}

	componentDidMount() {
		//if (this.props.personId != null) {
		this.updatePerson();
		//}
		window.addEventListener('resize', this.onWindowResize);
		this.updateSizes();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResize);
	}

	componentDidUpdate(prevProps) {
		if (this.props.personId !== prevProps.personId) {
			this.updatePerson();
		}
	}

	updatePerson() {
		this.setState({
			person: this.props.personId ? this.props.mainData.personList.find(p => p.id == this.props.personId) : this.getMindenkiFormMainData()
		});
		TagService.getPersonTagList(this.props.personId || null).then(result => this.setState({ personTagList: result }, this.updateTagListSize));
	}

	updateTagListSize() {
		if (this.scrollableTagContainer != null) {
			var elemRect = this.scrollableTagContainer.getBoundingClientRect();
			this.scrollableTagContainer.style.height = window.innerHeight - elemRect.top - this.state.bottomMargin + 'px';
		}
	}

	updateSizes() {
		this.updateTagListSize();
		var containerWidth = this.sideBarContainerParent.offsetWidth;
		this.sideBarContainer.style.width = containerWidth + 'px';
	}

	changeSideBarPosition(opt) {
		if (this.sideBarContainer != null) {
			if (opt) {
				this.sideBarContainer.style.position = 'absolute';
				this.sideBarContainer.style.bottom = '0';
				this.sideBarContainer.style.left = '0';
			} else {
				this.sideBarContainer.style.position = 'fixed';
				this.sideBarContainer.style.bottom = 'auto';
				this.sideBarContainer.style.left = 'auto';
			}
		}
	}

	handleTagChange(tag) {
		if (this.props.tag != null && this.props.tag.id == tag.id) {
			this.props.onTagChange(null);
		} else {
			this.props.onTagChange(tag);
		}
	}

	getMindenkiFormMainData() {
		return {
			id: null,
			firstName: typeof this.props.mainData.propertiesMap.firstName !== 'undefined' ? this.props.mainData.propertiesMap.firstName.value : '',
			lastName: typeof this.props.mainData.propertiesMap.lastName !== 'undefined' ? this.props.mainData.propertiesMap.lastName.value : '',
			profession: typeof this.props.mainData.propertiesMap.profession !== 'undefined' ? this.props.mainData.propertiesMap.profession.value : '',
			description: typeof this.props.mainData.propertiesMap.description !== 'undefined' ? this.props.mainData.propertiesMap.description.value : ''
		};
	}

	render() {
		return (
			<div className="main-page">
				<div className="main-page-wrap">
					<div className="main-page-sidebar-container" ref={el => (this.sideBarContainerParent = el)}>
						<div className="main-page-sidebar" ref={el => (this.sideBarContainer = el)}>
							<div>
								{this.state.person != null ? (
									<div className="main-page-sidebar-upper">
										<h1>
											{this.state.person.lastName}
											<br />
											{this.state.person.firstName}
										</h1>

										<div className="blueLine" />

										<h2>{this.state.person.profession}</h2>

										<div className="lead" dangerouslySetInnerHTML={{ __html: this.state.person.description }}></div>
									</div>
								) : null}
								<div className="scrollable-tag-container" ref={el => (this.scrollableTagContainer = el)}>
									<div className="scrollable-tag-content">
										{this.props.mainData && this.props.mainData.propertiesMap.showMainTagList.value === 1 && this.state.person && this.state.person.tagList != null ? (
											<div className="tag-content">
												{this.state.person.tagList.map(t => (
													<TagButton
														key={t.id}
														data={t}
														onClick={() => this.handleTagChange(t)}
														active={this.props.tag != null && this.props.tag.id == t.id}
														visible={this.props.serviceTagList != null && this.props.serviceTagList.findIndex(d => t.id == d.id) > -1}
													/>
												))}
											</div>
										) : null}
										{this.state.personTagList != null ? (
											<div className="tag-content">
												{this.props.tag != null &&
													this.state.personTagList
														.filter(t => this.props.tag.id == t.id)
														.map(t => (
															<TagButton
																key={t.id}
																data={t}
																onClick={() => this.handleTagChange(t)}
																active
																visible={this.props.serviceTagList != null && this.props.serviceTagList.findIndex(d => t.id == d.id) > -1}
															/>
														))}
												{(this.props.tag != null ? this.state.personTagList.filter(t => this.props.tag.id != t.id) : this.state.personTagList).map(t => (
													<TagButton key={t.id} data={t} onClick={() => this.handleTagChange(t)} visible={this.props.serviceTagList != null && this.props.serviceTagList.findIndex(d => t.id == d.id) > -1} />
												))}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="main-page-content">
						<ContentTimeline
							mainData={this.props.mainData}
							person={this.state.person}
							filterList={this.props.filterList}
							tag={this.props.tag}
							onTypeCountMap={typeCountMap => this.props.onTypeCountMap(typeCountMap)}
							onItemWidthChanged={w => this.props.onItemWidthChanged(w)}
							onServiceTagListChange={tagList => this.props.onServiceTagListChange(tagList)}
							searchQuery={this.props.searchQuery}
						></ContentTimeline>
					</div>
				</div>
				<Footer mainData={this.props.mainData} onHandleFooterVisible={footerVisible => this.changeSideBarPosition(footerVisible)} />
			</div>
		);
	}
}

export default withRouter(mainPage);
