import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ContentItem from './ContentItem';
import ListService from '../../service/ListService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TimelineBar from './TimelineBar';
import ContentStore from './ContentStore';

class ContentTimeline extends Component {
	constructor(props) {
		super(props);
		this.loadCount = 50;
		this.loadBuffer = [];
		this.isLoadingBuffer = [];
		this.bufferTimer = null;
		this.bufferTimeout = 250;
		this.timelineContainer = null;
		this.timelineResizeObserver = null;
		this.callCount = 0;
		this.state = {
			contentList: null,
			timelineBarList: null,
			contentKey: null,
			itemWidth: null,
			queryKey: null
		};
	}

	propsIsValidForGetList() {
		if (/*this.props.person != null &&*/ this.props.filterList.length > 0) return true;
		return false;
	}

	propsPersonChanged(prevProps) {
		if (prevProps.person == null && this.props.person == null) return false;
		if ((prevProps.person == null && this.props.person != null) || (prevProps.person != null && this.props.person == null) || prevProps.person.id != this.props.person.id) return true;
		return false;
	}

	propsFilterChanged(prevProps) {
		if (prevProps.filterList.length == 0 || prevProps.filterList.length != this.props.filterList.length) return true;
		if (prevProps.filterList.length == this.props.filterList.length) {
			for (var i = 0; i < this.props.filterList.length; i++) {
				if (prevProps.filterList.indexOf(this.props.filterList[i]) == -1) return true;
			}
		}
		return false;
	}

	propsTagChanged(prevProps) {
		if ((prevProps.tag == null && this.props.tag != null) || (prevProps.tag != null && this.props.tag == null) || (prevProps.tag != null && this.props.tag != null && prevProps.tag.id != this.props.tag.id)) {
			return true;
		}
		return false;
	}

	propsSearchQueryChanged(prevProps) {
		if (prevProps.searchQuery !== this.props.searchQuery) {
			return true;
		}
		return false;
	}

	componentDidMount() {
		this.timelineResizeObserver = new ResizeObserver(entries => {
			if (this.state.contentList != null) {
				var el = entries[0].target;
				this.updateTimeLineBar(el);
			}
		});
		this.timelineResizeObserver.observe(this.timelineContainer);
		if (this.propsIsValidForGetList()) {
			this.setState(
				{
					queryKey: ContentStore.queryKey
				},
				() => {
					ContentStore.clear();
					this.getContent();
				}
			);
		}
	}

	componentWillUnmount() {
		if (this.timelineResizeObserver != null) {
			this.timelineResizeObserver.unobserve(this.timelineContainer);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.propsIsValidForGetList() && (this.propsPersonChanged(prevProps) || this.propsFilterChanged(prevProps) || this.propsTagChanged(prevProps) || this.propsSearchQueryChanged(prevProps))) {
			if (this.propsPersonChanged(prevProps) || this.propsTagChanged(prevProps) || this.propsSearchQueryChanged(prevProps)) {
				this.setState(
					{
						queryKey: ContentStore.queryKey
					},
					() => {
						ContentStore.clear();
						this.getContent();
					}
				);
			} else {
				this.getContent();
			}
		}
		if (prevState.itemWidth != this.state.itemWidth) {
			this.props.onItemWidthChanged(this.state.itemWidth);
		}
	}

	getContent() {
		if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.contentKey !== 'undefined') {
			this.getContentByKey(this.props.location.state.contentKey);
		} else {
			this.getContentFromBeginning();
		}
	}

	getContentFromBeginning() {
		console.log('getContentFromBeginning');
		this.callCount++;
		let _callCount = this.callCount;
		ListService.getList(0, 0, this.loadCount * 2, this.props.person ? this.props.person.id : null, this.props.filterList, this.props.tag != null ? this.props.tag.name : null, this.state.queryKey, this.props.searchQuery).then(result => {
			if (_callCount != this.callCount) {
				return;
			}
			var contentList = new Array(result.listLength);
			contentList = contentList.fill(null);
			var startPos = result.listIndex - result.contentIndex;
			var arrayIndex = 0;
			contentList = contentList.map((item, i) => {
				if (i >= startPos && i < startPos + result.contentList.length) {
					var newItem = result.contentList[arrayIndex];
					arrayIndex++;
					return {
						key: result.listKeySet[i],
						loaded: true,
						data: newItem
					};
				}
				return {
					key: result.listKeySet[i],
					loaded: false,
					data: null
				};
			});
			this.setState(
				{
					contentList: contentList,
					queryKey: result.key
				},
				() => this.updateTimeLineBar(this.timelineContainer)
			);
			this.props.onTypeCountMap(result.typeCountMap);
			this.props.onServiceTagListChange(result.tagList);
		});
	}

	getContentByKey(contentKey) {
		console.log('getContentByKey: ' + contentKey);
		this.callCount++;
		let _callCount = this.callCount;
		ListService.getListByContent(
			contentKey,
			this.loadCount,
			this.loadCount,
			this.props.person ? this.props.person.id : null,
			this.props.filterList,
			this.props.tag != null ? this.props.tag.name : null,
			this.state.queryKey,
			this.props.searchQuery
		).then(result => {
			if (_callCount != this.callCount) {
				return;
			}
			var contentList = new Array(result.listLength);
			contentList = contentList.fill(null);
			var startPos = result.listIndex - result.contentIndex;
			var arrayIndex = 0;
			contentList = contentList.map((item, i) => {
				if (i >= startPos && i < startPos + result.contentList.length) {
					var newItem = result.contentList[arrayIndex];
					arrayIndex++;
					return {
						key: result.listKeySet[i],
						loaded: true,
						data: newItem
					};
				}
				return {
					key: result.listKeySet[i],
					loaded: false,
					data: null
				};
			});
			this.setState(
				{
					contentList: contentList,
					contentKey: contentKey,
					queryKey: result.key
				},
				() => {
					this.updateTimeLineBar(this.timelineContainer);
				}
			);
			this.props.onTypeCountMap(result.typeCountMap);
			this.props.onServiceTagListChange(result.tagList);
		});
	}

	handleNeedLoad(pos) {
		if (this.isLoadingBuffer.indexOf(pos) == -1 && this.loadBuffer.indexOf(pos) == -1) {
			this.loadBuffer.push(pos);
			if (this.bufferTimer != null) clearTimeout(this.bufferTimer);
			this.bufferTimer = setTimeout(
				function () {
					this.bufferTimer = null;
					this.cleanBuffer();
				}.bind(this),
				this.bufferTimeout
			);
		}
	}

	cleanBuffer() {
		var firstPos = Math.min(...this.loadBuffer);
		var queryLength = Math.max(...this.loadBuffer) - firstPos;
		this.isLoadingBuffer = this.loadBuffer;
		this.loadBuffer = [];
		this.callCount++;
		let _callCount = this.callCount;
		ListService.getList(firstPos, 0, queryLength, this.props.person ? this.props.person.id : null, this.props.filterList, this.props.tag != null ? this.props.tag.name : null, this.state.queryKey, this.props.searchQuery).then(result => {
			if (_callCount != this.callCount) {
				return;
			}
			this.isLoadingBuffer = [];
			var contentList = this.state.contentList;
			var startPos = result.listIndex - result.contentIndex;
			var arrayIndex = 0;
			contentList = contentList.map((item, i) => {
				if (i >= startPos && i < startPos + result.contentList.length) {
					var newItem = result.contentList[arrayIndex];
					arrayIndex++;
					return {
						key: result.listKeySet[i],
						loaded: true,
						data: newItem
					};
				}
				return item;
			});
			console.log('cleanBuffer: ' + startPos + ' - ' + (startPos + result.contentList.length));
			this.setState(
				{
					contentList: contentList
				},
				() => this.updateTimeLineBar(this.timelineContainer)
			);
		});
	}

	updateTimeLineBar(container) {
		if (this.state.contentList.length > 0 && container.childElementCount > 0) {
			var containerRect = container.getBoundingClientRect();
			var timelineBarResult = [];
			var lastColumn = 0;
			var itemWidth = container.children[0].getBoundingClientRect().width;
			var columnCount = Math.floor(containerRect.width / itemWidth);
			for (var i = 0; i < container.childElementCount; i++) {
				var el = container.children[i];
				var elRect = el.getBoundingClientRect();
				var top = elRect.top - containerRect.top;
				var left = elRect.left - containerRect.left;
				if (i == 0 || i % columnCount == 0) {
					var contentKey = el.dataset.key;
					var item = this.state.contentList.find(c => c.key == contentKey);
					if (item.loaded) {
						timelineBarResult.push({
							key: contentKey,
							dateString: item.data.dateString,
							dateYear: item.data.dateYear,
							position: top
						});
					}
				}
			}
			this.setState({
				timelineBarList: timelineBarResult,
				itemWidth: itemWidth
			});
		} else {
			this.setState({
				timelineBarList: [],
				itemWidth: itemWidth
			});
		}
	}

	handleOnSelect() {
		ContentStore.queryKey = this.state.queryKey;
		ContentStore.filterList = this.props.filterList;
		ContentStore.tag = this.props.tag;
	}
	/*
    setScrollToKey(contentKey) {
        if( this.timelineContainer.childElementCount > 0 ) {
            var containerRect = this.timelineContainer.getBoundingClientRect();
            for(var i = 0 ; i < this.timelineContainer.childElementCount ; i++ ) {
                var el = this.timelineContainer.children[i];
                var currentContentKey = el.dataset.key;
                if( currentContentKey == contentKey ) {
                    var elRect = el.getBoundingClientRect();
                    console.log(currentContentKey + ": " + elRect.top  + " " + elRect.height);
                    break;
                }
            }
        }
    }
*/
	render() {
		return (
			<Container fluid className="content-timeline-box">
				<Row>
					<Col>
						<div className="content-timeline" ref={el => (this.timelineContainer = el)}>
							{this.state.contentList ? (
								this.state.contentList.length > 0 ? (
									this.state.contentList.map((item, i) => (
										<ContentItem
											key={item.key}
											item={item}
											person={this.props.person}
											mainData={this.props.mainData}
											activate={this.state.contentKey != null && this.state.contentKey == item.key}
											onNeedLoad={() => this.handleNeedLoad(i)}
											onSelect={() => this.handleOnSelect()}
										/>
									))
								) : (
									<div className="no-result">Nincs találat</div>
								)
							) : (
								'Betöltés alatt ...'
							)}
						</div>
					</Col>
					<TimelineBar itemWidth={this.state.itemWidth} data={this.state.timelineBarList}></TimelineBar>
				</Row>
			</Container>
		);
	}
}

export default withRouter(ContentTimeline);
