import MainDto from "../dto/main/MainDto";
import nBossConnection from "./nBossConnection";

class MainService {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = "mainControl";
    }

    /**
    * @return {Promise<MainDto>}
    */
    getData(id) {
        return new Promise((resolve, reject) => {
            this.connection.post('/' + this.CONTROL + '/getData', {
                id: id,
            }).then((response) =>
                resolve(response.data)
            ).catch(reject);
        });
    }
}

export default new MainService();