import React, { Component } from 'react';
import TagService from '../../service/TagService';
import TagButton from '../../items/TagButton';
import LogoutMenu from './LogoutMenu';
import SearchInput from './SearchInput';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			person: null,
			personTagList: null,
			item1open: false,
			item2open: false,
			item3open: false
		};
	}

	componentDidMount() {
		if (this.props.mainData != null && this.props.personId != null) {
			this.updatePerson();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.mainData != null && this.props.personId !== prevProps.personId) {
			this.updatePerson();
		}
	}

	updatePerson() {
		this.setState({
			person: this.props.mainData.personList.find(p => p.id == this.props.personId)
		});
		TagService.getPersonTagList(this.props.personId || null).then(result => this.setState({ personTagList: result }));
	}

	handleTagChange(tag) {
		if (this.props.tag != null && this.props.tag.id == tag.id) {
			this.props.onTagChange(null);
		} else {
			this.props.onTagChange(tag);
		}
	}

	render() {
		return (
			<div className={'menu' + (this.props.open ? ' opened' : '')}>
				<div className="menu-wrap">
					<SearchInput searchQuery={this.props.searchQuery} onChange={q => this.props.onSearchChange(q)} />
					<ul>
						{this.state.person != null ? (
							<li>
								<div className="menu-item" onClick={() => this.setState({ item1open: !this.state.item1open })}>
									{this.state.person.name}
								</div>
								<div className={'menu-content' + (this.state.item1open ? ' opened' : '')} dangerouslySetInnerHTML={{ __html: this.state.person.description }}></div>
							</li>
						) : null}
						<li>
							<div className="menu-item" onClick={() => this.setState({ item2open: !this.state.item2open })}>
								Impresszum
							</div>
							<div className={'menu-content' + (this.state.item2open ? ' opened' : '')}>
								{this.props.mainData != null ? this.props.mainData.propertiesMap['impressum-copyright'].value : null}
								<br />
								<br />
								{this.props.mainData != null ? (
									<p>
										Kontakt:{' '}
										<a className="menu-email" href={'mailto: ' + this.props.mainData.propertiesMap['impressum-email'].value}>
											{this.props.mainData.propertiesMap['impressum-email'].value}
										</a>
									</p>
								) : null}
							</div>
						</li>
						<li>
							<div className="menu-item" onClick={() => this.setState({ item3open: !this.state.item3open })}>
								Címkék
							</div>
							<div className={'menu-content' + (this.state.item3open ? ' opened' : '')}>
								{this.props.mainData && this.props.mainData.propertiesMap.showMainTagList.value === 1 && this.state.person != null && this.state.person.tagList != null ? (
									<div className="tag-content">
										{this.state.person.tagList.map(t => (
											<TagButton
												key={t.id}
												data={t}
												onClick={() => this.handleTagChange(t)}
												active={this.props.tag != null && this.props.tag.id == t.id}
												visible={this.props.serviceTagList != null && this.props.serviceTagList.findIndex(d => t.id == d.id) > -1}
											/>
										))}
									</div>
								) : null}
								{this.state.personTagList != null ? (
									<div className="tag-content">
										{this.state.personTagList.map(t => (
											<TagButton
												key={t.id}
												data={t}
												onClick={() => this.handleTagChange(t)}
												active={this.props.tag != null && this.props.tag.id == t.id}
												visible={this.props.serviceTagList != null && this.props.serviceTagList.findIndex(d => t.id == d.id) > -1}
											/>
										))}
									</div>
								) : null}
							</div>
						</li>
						<LogoutMenu onLogout={() => this.props.onLogout()} />
					</ul>
				</div>
			</div>
		);
	}
}

export default Menu;
