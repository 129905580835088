import React, { Component } from 'react'

class DetailsTimelineBar extends Component {
    constructor(props) {
        super(props);
        this. mainResizeObserver = null;
        this.state = {
            slidePos: 0,
            transitionEnabled: true,
            currentContentKey: null
        }
    }

    componentDidMount() {
        this.mainResizeObserver = new ResizeObserver(entries => {
            if( this.props.items != null )
                this.updateSize();
        });
        this.mainResizeObserver.observe(this.mainContainer);
        if( this.props.items != null ) {
            if( this.props.contentKey != null ) {
                var contentPos = this.props.items.indexOf(this.props.items.find((item) => item.key == this.props.contentKey));
                var diff = Math.abs(this.state.slidePos - contentPos);
                this.setState({
                    slidePos: contentPos,
                    transitionEnabled: diff < 2,
                    currentContentKey: this.props.items[contentPos].key
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if( this.props.items != null ) {
            this.updateSize();
            if( this.props.contentKey != null && 
                (prevProps.contentKey == null || this.props.contentKey != prevProps.contentKey) ) {
                var contentPos = this.props.items.indexOf(this.props.items.find((item) => item.key == this.props.contentKey));
                var diff = Math.abs(this.state.slidePos - contentPos);
                this.setState({
                    slidePos: contentPos,
                    transitionEnabled: diff < 2,
                    currentContentKey: this.props.items[contentPos].key
                });
            }
        }
    }
    
    componentWillUnmount() {
        if( this.mainResizeObserver != null ) {
            this.mainResizeObserver.unobserve(this.mainContainer);
        }
    }

    updateSize() {
        var containerHeight = this.mainContainer.offsetHeight;
        this.wrapContainer.style.height = (containerHeight * this.props.items.length) + 'px'
        if( this.wrapContainer.childElementCount > 0 ) {
            for(var i = 0 ; i < this.wrapContainer.childElementCount ; i++ ) {
                var el = this.wrapContainer.children[i];
                el.style.paddingBottom = this.props.slideBottomHeight + 'px';
            }
        }
        this.updatePos();
    }
    
    calcPosOffset(pos) {
        return pos * this.mainContainer.offsetHeight * -1;
    }

    updatePos() {
        this.wrapContainer.style.top = this.calcPosOffset(this.state.slidePos) + "px";
    }

    render() {
        return (
            <div className="details-timeline" ref={el => (this.mainContainer = el)}>
                <div className={'details-timeline-wrap' + (this.state.transitionEnabled ? ' details-timeline-transition' : '')} ref={el => (this.wrapContainer = el)}>
                    {this.props.items != null ?
                        this.props.items.map((i, ii) => (
                            <div key={ii} className="timeline-item">
                                <div className="timeline-item-label">{i.loaded? i.data.dateYear : null}</div>
                            </div>
                        )) : null }
                </div>
            </div>
        )
    }
}
export default DetailsTimelineBar;